const menu = [
  {
    label: "Home",
    url: "/home",
  },
  {
    label: "About Us",
    url: "/about-us",
  },
  {
    label: "Technology Development",
    url: "/technology-development",
  },
];

export default menu;
