import { lazy } from "react";

const Home = lazy(() => import("./views/Home"));
const AboutUs = lazy(() => import("./views/AboutUs"));
const TechnologyDevelopment = lazy(() =>
  import("./views/TechnologyDevelopment")
);

export const routes = [
  { path: "/", exact: true, name: "Home", component: Home },
  { path: "/home", name: "Home", component: Home },
  { path: "/about-us", name: "About Us", component: AboutUs },
  {
    path: "/technology-development",
    name: "Technology Development",
    component: TechnologyDevelopment,
  },
];
