import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'
import menu from '../../menu-items'
import { useTranslation } from 'react-i18next'

const social = [
  {
    icon: 'fa fa-facebook',
    url: 'https://www.facebook.com/profile.php?id=61567517971336&mibextid=LQQJ4d',
    cn: 'https://www.facebook.com/profile.php?id=61567517971336&mibextid=LQQJ4d'
  },
  {
    icon: 'fa fa-instagram',
    url: 'https://www.instagram.com/apexiscapital',
    cn: 'https://www.instagram.com/apexiscapital'
  },
  {
    icon: 'fa fa-twitter',
    url: 'https://x.com/apexiscapital23',
    cn: 'https://x.com/apexiscapital23'
  }
]

const Footer = () => {
  const { t, i18n } = useTranslation()

  return (
    <footer className='footer-light'>
      <div className='subfooter'>
        <div className='container'>
          <Row className='footer-menu'>
            <Col md='auto' xs={12} className='mb-3'>
              <Image src='../logo.png' alt='logo' style={{ width: '120px' }} />
            </Col>

            <Col lg={6} md xs={12} className='mb-3'>
              <Row className='justify-content-center'>
                {menu.map((menu, index) => (
                  <Col key={index} xs='auto'>
                    <a href={menu.url}>{t(menu.label)}</a>
                  </Col>
                ))}
              </Row>
            </Col>

            <Col id='social' xs='auto' className='text-end'>
              {social.map((item, index) => (
                <a
                  key={index}
                  href={i18n.language === 'en' ? item.url : item.cn}
                  target='_blank'
                  rel='noreferrer'
                >
                  <i className={item.icon} />
                </a>
              ))}
            </Col>
          </Row>
          <Row className='text-center mb-3'>
            <span className='copy'>{t('Copyright')} &copy; 2024, Apexis.</span>
          </Row>
        </div>
      </div>
    </footer>
  )
}
export default Footer
